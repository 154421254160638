<template>
  <nav class="navbar navbar-light bg-light p-0 my-3">
    <router-link class="navbar-brand" :to="{ name: 'Overview' }">
      <img src="/images/R-Cycle_Logo_RGB_300.png" height="50" alt="" loading="lazy">
    </router-link>
    <LoginComponent class="ml-auto"/>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex';
import {AUTH_LOGOUT, AUTH_REQUEST} from '@/vuex/actions/auth';
import LoginComponent from './LoginComponent';

export default {
  name: 'AppHeader',
  data: function () {
    return {
      user: {
        username: '',
        password: ''
      },
      loading: false,
      loginError: false
    };
  },
  computed: {
    ...mapGetters({
      isProfileLoaded: 'isProfileLoaded',
      isAuthenticated: 'isAuthenticated'
    })
  },
  methods: {
    login: function () {
      this.loginError = false;
      this.loading = true;
      this.$store
        .dispatch(AUTH_REQUEST, this.user)
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('login-modal')
          });
        })
        .catch(() => {
          console.log('error');
          this.loginError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.user.username = "";
        this.user.password = "";
      });
    }
  },
  components: {
    LoginComponent
  }
}
</script>
