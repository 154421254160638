import Vue from 'vue';
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

library.add(faSpinner);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

dom.watch();
