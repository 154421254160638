<template>
  <b-form @submit.prevent="login" class="text-left">
    <b-alert :show="loginError" variant="danger">Failed to Login. Please check your inputs.</b-alert>
    <b-form-group id="username-form-group" label="Username" label-for="username">
      <b-form-input id="username" v-model="user.username" type="text" required></b-form-input>
    </b-form-group>
    <b-form-group id="password-form-group" label="Password" label-for="password">
      <b-form-input id="password" v-model="user.password" type="password" required></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="success"
              :disabled="user.username.length === 0 || user.password.length === 0">
      <font-awesome-icon icon="spinner" spin v-if="loading"></font-awesome-icon>
      Login
    </b-button>
  </b-form>
</template>

<script>
import {
  mapGetters
} from "vuex";
import {
  AUTH_REQUEST
} from "@/vuex/actions/auth";

export default {
  name: 'LoginForm',
  data: function () {
    return {
      user: {
        username: '',
        password: ''
      },
      loading: false,
      loginError: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    })
  },
  methods: {
    login: function () {
      this.loginError = false;
      this.loading = true;
      this.$store
        .dispatch(AUTH_REQUEST, this.user)
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('login-modal')
          });
        })
        .catch(() => {
          console.log('error');
          this.loginError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
