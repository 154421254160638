import Vue from 'vue'
import App from './App.vue'
import router from '@/router';
import axios from '@/axios';
import store from '@/vuex';
import '@/fontawesome';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import FlashMessage from '@smartweb/vue-flash-message';

import './scss/main.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Dialog from 'vue-dialog-loading'

Vue.use(BootstrapVue);
Vue.use(FlashMessage);

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$unavailable = 'n'

new Vue({
    router,
    store,
    render: function (CreateElement) {
        return CreateElement(App)
    }
}).$mount('#app');

Vue.filter('normFilter', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.substring(25, 42)
})

Vue.use(Dialog, {
    dialogBtnColor: '#0f0'
})
