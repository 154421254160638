<template>
  <p class="text-right mr-2 ml-auto mt-auto mb-auto">{{ version }}</p>
</template>

<script>

export default {
  name: 'Version',
  data: function () {
    return {
      version: ''
    };
  },
  mounted() {
    this.$axios
      .get('/version')
      .then(response => {
        this.version = response.data ? 'v' + response.data : '';
      })
      .catch(e => {
        console.error('GET /version request failed: ' + e);
      });
  }
}
</script>
